import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import useState from 'react-hook-use-state';
import { NavigateBefore } from '@material-ui/icons';
import { navigate, navigateTo } from 'gatsby';
import Button from './Button';
import Layout from './layout/Layout';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 160
    }
  }
}));

export default function ValidationTextFields() {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const handleChange = e => {
    e.preventDefault();
    console.log('Typed => ${e.target.value');

    if (
      // ->Maxvorstadt<-
      (value === '80333') |
      (value === '80335') |
      (value === '80636') |
      (value === '80539') |
      (value === '80802') |
      (value === '80801') |
      (value === '80799') |
      (value === '80797') |
      (value === '80798') |
      (value === '80335') |
      (value === '80335') |
      (value === '80335') |
      (value === '80335') |
      (value === '80335') |
      (value === '80335') |
      // ->Schwanthalerhöhe<-
      (value === '80339') |
      (value === '80335') |
      // ->Ludwigsvorstadt-isarvorstadt<-
      (value === '80335') |
      (value === '80336') |
      (value === '80337') |
      (value === '80469') |
      // ->Schwabing west<-
      (value === '80796') |
      (value === '80797') |
      (value === '80798') |
      (value === '80799') |
      (value === '80801') |
      (value === '80803') |
      (value === '80804') |
      (value === '80809') |
      // ->Altstadt Lehel:<-
      (value === '80331') |
      (value === '80333') |
      (value === '80335') |
      (value === '80336') |
      (value === '80469') |
      (value === '80538') |
      (value === '80539') |
      // ->Au-Haidhausen<-
      (value === '81541') |
      (value === '81543') |
      (value === '81667') |
      (value === '81669') |
      (value === '81671') |
      (value === '81675') |
      (value === '81677') |
      // ->Bogenhausens<-
      (value === '81675') |
      (value === '81677') |
      (value === '81679') |
      (value === '81925') |
      (value === '81927') |
      (value === '81929') |
      // ->Sendling<-
      (value === '80336') |
      (value === '80337') |
      (value === '80469') |
      (value === '81369') |
      (value === '81371') |
      (value === '81373') |
      (value === '81379') |
      /// /->Allach-Untermenzing<-
      (value === '80995') |
      (value === '80997') |
      (value === '80999') |
      (value === '81247') |
      (value === '81249') |
      // ->Aubing-Lochhausen-Langwied<-
      (value === '81241') |
      (value === '81243') |
      (value === '81245') |
      (value === '81249') |
      // -> Berg am Laim<-
      (value === '81671') |
      (value === '81673') |
      (value === '81677') |
      (value === '81735') |
      (value === '81825') |
      (value === '81829') |
      // ->  Feldmoching-Hasenbergl<-
      (value === '80933') |
      (value === '80935') |
      (value === '80995') |
      // -> Hadern<-
      (value === '80689') |
      (value === '81375') |
      (value === '81377') |
      // -> Laim<-
      (value === '80639') |
      (value === '80686') |
      (value === '80687') |
      (value === '80689') |
      // -> Milbertshofen-Am Hart<-
      (value === '80797') |
      (value === '80807') |
      (value === '80809') |
      (value === '80933') |
      (value === '80935') |
      (value === '80937') |
      (value === '80939') |
      // ->  Moosach<-
      (value === '80637') |
      (value === '80638') |
      (value === '80992') |
      (value === '80993') |
      (value === '80995') |
      (value === '80997') |
      // ->   Neuhausen-Nymphenburg<-
      (value === '80335') |
      (value === '80634') |
      (value === '80636') |
      (value === '80637') |
      (value === '80638') |
      (value === '80639') |
      (value === '80797') |
      (value === '80809') |
      (value === '80992') |
      // ->    Obergiesing-Fasangarten<-
      (value === '81539') |
      (value === '81541') |
      (value === '81547') |
      (value === '81549') |
      // ->   Pasing-Obermenzing<-
      (value === '80687') |
      (value === '80689') |
      (value === '80992') |
      (value === '80997') |
      (value === '81241') |
      (value === '81243') |
      (value === '81245') |
      (value === '81247') |
      (value === '81249') |
      // ->   Ramersdorf-Perlach<-
      (value === '81539') |
      (value === '81541') |
      (value === '81549') |
      (value === '81669') |
      (value === '81671') |
      (value === '81735') |
      (value === '81737') |
      (value === '81739') |
      (value === '81827') |
      // ->    Schwabing-Freimann<-
      (value === '80538') |
      (value === '80539') |
      (value === '80939') |
      (value === '80801') |
      (value === '80802') |
      (value === '80803') |
      (value === '80804') |
      (value === '80805') |
      (value === '80807') |
      // ->     Sendling-Westpark<-
      (value === '80686') |
      (value === '81369') |
      (value === '81373') |
      (value === '81377') |
      (value === '81379') |
      // ->     Thalkirchen-Obersendling<-
      (value === '81369') |
      (value === '81371') |
      (value === '81379') |
      (value === '81475') |
      (value === '81476') |
      (value === '81477') |
      (value === '81479') |
      // ->     Trudering-Riem<-
      (value === '81735') |
      (value === '81825') |
      (value === '81827') |
      (value === '81829') |
      (value === '85540') |
      // ->      Untergiesing-Harlaching<-
      (value === '81539') |
      (value === '81543') |
      (value === '81545') |
      (value === '81547') |
      (value === '81549')
    ) {
      navigate(`/Booking?zip=${value}`);
    } else {
      window.location.href = 'https://mailchi.mp/e6614edd2721/recycular';
    }
  };

  return (
    <div className="py-2 text-center">
      <h1>
        Prüfe hier, ob es
        <p className="text-blue-500"> re.cycular </p> schon in deiner Stadt gibt.
      </h1>
      <form className={classes.root} onSubmit={handleChange}>
        <div>
          <TextField
            className="text-center text-blue-500 "
            label="PLZ"
            onChange={e => setValue(e.target.value)}
            defaultValue=""
            value={value}
            helperText="Postleitzahl eingeben."
            onInput={e => {
              const number = parseInt(e.target.value);
              if (Number.isNaN(number)) e.target.value = '';
              else
                e.target.value = Math.max(0, number)
                  .toString()
                  .slice(0, 5);
            }}
            variant="outlined"
            type="text"
            autoComplete="postal-code"
          />
        </div>

        <div className="text">
          <button type="submit" className="font-bold">
            Los
          </button>
        </div>
      </form>
    </div>
  );
}
