/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { navigate } from 'gatsby';
import PLZ from '../PLZ';
import 'reactjs-popup/dist/index.css';
import MenuIcon from '../../assets/menu.svg';
import CloseIcon from '../../assets/close.svg';
import AboutIcon from '../../assets/about.svg';
import BulbIcon from '../../assets/bulb.svg';
import NewsIcon from '../../assets/news.svg';
import PersonIcon from '../../assets/person.svg';
import Zip from '../../data/zip';

const Header = () => {
  const [menuOpen, setOpenMenu] = useState(false);
  const [showClb, setShowClb] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [all, setAll] = useState(true);
  const [analytics, setAnalytics] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [value, setValue] = useState('');

  const handleZipCheck = () => {
    if (Zip.includes(value)) {
      navigate('/Booking');
    } else {
      window.location.href = 'https://mailchi.mp/e6614edd2721/recycular';
    }
  };

  const handleAccept = () => {
    Cookies.set('cookie-consent', true, { expires: 100 });
    if (all || analytics) {
      Cookies.set('gdpr-analytics-enabled', true);
    }

    if (all || marketing) {
      Cookies.set('gdpr-marketing-enabled', true);
    }
    setShowClb(false);
    window.location.reload();
  };

  useEffect(() => {
    if (Cookies.get('cookie-consent')) {
      setShowClb(false);
    }
  }, []);

  return (
    <>
      {showClb && (
        <>
          <div className="clb-background" />
          <div className="clb-banner w-11/12 md:w-6/12">
            <h2 className="text-xl lg:text-xl xl:text-2xl font-bold text-blue-500 leading-none mb-4">
              Wir wollen, dass du happy bist.
            </h2>
            <p>
              Diese Internetseite verwendet Cookies, u.a. für die Analyse und Statistik. Cookies
              helfen uns, die Benutzerfreundlichkeit unserer Website zu verbessern. Durch Klicken
              auf „Accept“ stimmst du dem Setzen von Cookies zu Analyse und Statistik Zwecken zu.
              Weitere Informationen hierzu findest du in unserer
              <a href="https://recycular.de/datenschutz/" target="_blank" rel="noopener noreferrer">
                {' '}
                Datenschutzerklärung
              </a>
              .
            </p>
            {showSettings && (
              <div className="mt-8">
                <div className="flex align-items-center gap-8 md:gap-20">
                  <label className="switch">
                    <input type="checkbox" checked={all} onChange={() => setAll(!all)} />
                    <span className="slider round" />
                  </label>
                  <p className="font-bold">Alles auswählen</p>
                </div>
                <div className="flex align-items-center gap-8 md:gap-20 mt-4">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={analytics || all}
                      onChange={() => setAnalytics(!analytics)}
                    />
                    <span className="slider round" />
                  </label>
                  <p className="w-2/3">
                    <span className="font-bold">Web Performance und Analyse</span>
                    <br />
                    Wir verwenden diese Daten, um die Performance unserer Webseiten und Services zu
                    verbessern und um geschäftsrelevante Analysen zum Erlebnis mit unseren Apps und
                    Webseiten durchzuführen.
                  </p>
                </div>
                <div className="flex align-items-center gap-8 md:gap-20 mt-4">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={marketing || all}
                      onChange={() => setMarketing(!marketing)}
                    />
                    <span className="slider round" />
                  </label>
                  <p className="w-2/3">
                    <span className="font-bold">Marketing</span>
                    <br />
                    Recycular sammeln User-Journey-Daten, um dir relevante gesponserte Inhalte zu
                    unseren Produkten zu zeigen.
                  </p>
                </div>
              </div>
            )}
            <div className="mt-8 flex flex-col md:flex-row justify-end gap-4">
              <button
                type="button"
                className="button h-10  px-8 w-full md:w-1/3 button border-2 border-primary rounded text-primary button mr-3"
                onClick={() => setShowSettings(!showSettings)}
              >
                Einstellungen
              </button>
              <button
                type="button"
                className="button h-10 px-8 button bg-primary hover:bg-primary-darker rounded text-white button"
                onClick={() => handleAccept()}
              >
                Accept
              </button>
            </div>
          </div>
        </>
      )}

      <header className="sticky top-0 bg-white shadow z-10">
        <div className="container flex justify-between items-center mx-auto py-4 px-8">
          <a href="/" className="flex items-center text-2xl font-bold">
            re.cycular{' '}
          </a>

          <div className="mt-4 sm:mt-0 hidden md:flex">
            <a href="/#Ablauf" className="px-4">
              Ablauf{' '}
            </a>
            <a href="/#Vorteile" className="px-4">
              Unsere Vorteile{' '}
            </a>

            <a href="/Blog/" className="px-4">
              Blog{' '}
            </a>
            <a href="/About/" className="px-4">
              Über uns{' '}
            </a>
          </div>
          <div className="w-1/12 block md:hidden">
            <MenuIcon onClick={() => setOpenMenu(true)} />
          </div>

          <div className="hidden md:block">
            <Popup
              trigger={
                <button
                  className="button py-3 px-8 button bg-primary hover:bg-primary-darker rounded text-white button"
                  type="button"
                >
                  Jetzt Buchen{' '}
                </button>
              }
              modal
            >
              <PLZ />
            </Popup>
          </div>
        </div>
        <div
          className="mobile-nav"
          style={{
            transform: menuOpen ? 'translateX(0%)' : 'translateX(100%)'
          }}
        >
          <div className="mobile-nav__close">
            <a href="/" className="text-2xl font-bold">
              re.cycular{' '}
            </a>

            <CloseIcon onClick={() => setOpenMenu(false)} />
          </div>
          <div className="mobile-nav__menu">
            <a href="/#Ablauf" className="text-xl" onClick={() => setOpenMenu(false)}>
              Ablauf <AboutIcon />
            </a>
            <a href="/#Vorteile" className="text-xl" onClick={() => setOpenMenu(false)}>
              Unsere Vorteile <BulbIcon />
            </a>

            <a href="/Blog/" className="text-xl">
              Blog <NewsIcon />
            </a>
            <a href="/About/" className="text-xl">
              Über uns <PersonIcon />
            </a>
          </div>
          <div className="mobile-nav__plz">
            <h2 className="text-center text-lg mb-2">
              Verfügbarkeit prüfen und sofort abholung buchen!
            </h2>
            <TextField
              className="text-center text-blue-500 "
              label="Deine PLZ"
              variant="outlined"
              type="text"
              onChange={e => setValue(e.target.value)}
              value={value}
              onInput={e => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 5);
              }}
              error={value.length > 5}
              helperText={value.length > 5 && 'Bitte gib eine 4-Stellige PLZ ein'}
            />
            <button
              className="button py-3 px-8 bg-primary hover:bg-primary-darker rounded text-white disabled:opacity-50"
              type="button"
              onClick={handleZipCheck}
              disabled={value === ''}
            >
              Jetzt buchen
            </button>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
