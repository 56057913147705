import React, { useEffect, useState } from 'react';
import { SocialIcon } from 'react-social-icons';
import PayPalIcon from '../../assets/paypal.svg';
import MastercardIcon from '../../assets/mastercard.svg';
import VisaIcon from '../../assets/visa.svg';
import AmexIcon from '../../assets/amex.svg';
import SofortIcon from '../../assets/sofort.svg';
import GiropayIcon from '../../assets/giropay.svg';

const Footer = () => {
  const path = typeof window !== 'undefined' ? window.location.pathname : '';

  return (
    <footer
      className={
        path !== '/Booking'
          ? 'sticky top-0 bg-white shadow mx-auto py-4 px-5 md:px-20 mt-10'
          : ' bg-white shadow mx-auto py-2 md:px-20 mt-10'
      }
    >
      <div
        className={
          path !== '/Booking'
            ? 'container flex flex-col sm:flex-row mx-auto py-4 px-8'
            : 'container flex flex-col sm:flex-row mx-auto'
        }
      >
        {path !== '/Booking' && (
          <>
            <div className="flex-1 px-3 py-4">
              <h2 className="text-lg font-semibold ">Über Uns </h2>
              <ul className="mt-4 leading-loose">
                <li>
                  <a href="/About/" className="mt-5">
                    Erfahre mehr...
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-1 px-3 py-4">
              <h2 className="text-lg font-semibold">Kontakt</h2>
              <p className="mt-5">info@recycular.com</p>
            </div>
            <div className="flex-1 px-3 py-4">
              <h2 className="text-lg font-semibold">Social Media</h2>
              <ul className="mt-4 leading-loose">
                <SocialIcon
                  url="https://www.instagram.com/re.cycular/"
                  target="_blank"
                  className="mr-2"
                />
                <SocialIcon
                  url="https://www.facebook.com/Recycular"
                  target="_blank"
                  className="mr-2"
                />
                <SocialIcon url="https://linkedin.com/company/re-cycular" target="_blank" />
              </ul>
            </div>
          </>
        )}
        <div className={path !== '/Booking' ? 'flex-1 px-3 py-4' : 'flex-1'}>
          {path !== '/Booking' && <h2 className="text-lg font-semibold">Rechtliches</h2>}
          <ul
            className={
              path !== '/Booking' ? 'mt-4 leading-loose' : 'mt-4 leading-loose hier flex gap-4'
            }
          >
            <li>
              <a href="/imprint/">Impressum</a>
            </li>

            <li>
              <a href="/agb/">AGB</a>
            </li>
            <li>
              <a href="/datenschutz/">Datenschutzerklärung</a>
            </li>
          </ul>
        </div>
      </div>
      {path !== '/Booking' && (
        <div className="container mx-auto flex flex-col items-center justify-center gap-3">
          <h6 className="text-center">Sicher bezahlen mit deiner bevorzugten Bezahlungsmethode.</h6>
          <div className="flex gap-3">
            <PayPalIcon />
            <MastercardIcon />
            <VisaIcon />
            <AmexIcon />
            <SofortIcon />
            <GiropayIcon />
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
