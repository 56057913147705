export default [
  '80333',
  '80335',
  '80636',
  '80539',
  '80802',
  '80801',
  '80799',
  '80797',
  '80798',
  '80335',
  '80335',
  '80335',
  '80335',
  '80335',
  '80335',
  '80339',
  '80335',
  '80335',
  '80336',
  '80337',
  '80469',
  '80796',
  '80797',
  '80798',
  '80799',
  '80801',
  '80803',
  '80804',
  '80809',
  '80331',
  '80333',
  '80335',
  '80336',
  '80469',
  '80538',
  '80539',
  '81541',
  '81543',
  '81667',
  '81669',
  '81671',
  '81675',
  '81677',
  '81675',
  '81677',
  '81679',
  '81925',
  '81927',
  '81929',
  '80336',
  '80337',
  '80469',
  '81369',
  '81371',
  '81373',
  '81379',
  '80995',
  '80997',
  '80999',
  '81247',
  '81249',
  '81241',
  '81243',
  '81245',
  '81249',
  '81671',
  '81673',
  '81677',
  '81735',
  '81825',
  '81829',
  '80933',
  '80935',
  '80995',
  '80689',
  '81375',
  '81377',
  '80639',
  '80686',
  '80687',
  '80689',
  '80797',
  '80807',
  '80809',
  '80933',
  '80935',
  '80937',
  '80939',
  '80637',
  '80638',
  '80992',
  '80993',
  '80995',
  '80997',
  '80335',
  '80634',
  '80636',
  '80637',
  '80638',
  '80639',
  '80797',
  '80809',
  '80992',
  '81539',
  '81541',
  '81547',
  '81549',
  '80687',
  '80689',
  '80992',
  '80997',
  '81241',
  '81243',
  '81245',
  '81247',
  '81249',
  '81539',
  '81541',
  '81549',
  '81669',
  '81671',
  '81735',
  '81737',
  '81739',
  '81827',
  '80538',
  '80539',
  '80939',
  '80801',
  '80802',
  '80803',
  '80804',
  '80805',
  '80807',
  '80686',
  '81369',
  '81373',
  '81377',
  '81379',
  '81369',
  '81371',
  '81379',
  '81475',
  '81476',
  '81477',
  '81479',
  '81735',
  '81825',
  '81827',
  '81829',
  '85540',
  '81539',
  '81543',
  '81545',
  '81547',
  '81549'
];
